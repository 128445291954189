import cls from '@/helpers/cls';
import { InputHTMLAttributes, forwardRef } from 'react';

export default forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & { underline?: boolean }>(function TextInput(
  { type = 'text', className = '', underline, disabled, ...props },
  ref,
) {
  return (
    <input
      {...props}
      disabled={disabled}
      type={type}
      className={cls(
        underline
          ? 'border-transparent border-b-white bg-transparent px-0 focus:border-transparent focus:border-b-white focus:ring-0'
          : 'rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary',
        !underline && disabled && 'bg-slate-50 shadow-none',
        className,
      )}
      ref={ref}
    />
  );
});
